import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
import Footer from '../components/Footer/Footer';
import { headData } from '../data/data';
import '../style/text-page.scss';
import ProjectImg from '../components/Image/ProjectImg';

const renderFaq = () => {
  return (
    <div className="text-container">
      <h1>よくあるご質問</h1>
      <p>お問い合わせで聞かれる質問をまとめました。音楽を利用する前にご確認ください。</p>
      <div>
        <h2>無料で利用可能ですか？</h2>
        <p>はい、無料で利用が可能です。</p>
      </div>
      <div>
        <h2>商用利用できますか？</h2>
        <p>はい、商用利用が可能です。</p>
        <p>
          YouTubeなどを始めとする動画・映像制作や、ウェブコンテンツ制作、ゲーム、ラジオなどのBGMとして利用できます。
        </p>
        <p>
          ただし、ここで配布されている音楽自体をコンテンツにせず、あくまで創作物に用いる音楽の一部としてお使い下さい。
          <br />
          素材配布目的の雑誌やサイトなどで、音楽をそのまま二次配布したり、販売することはできません。
        </p>
        <p>
          また、配布されている音楽を編集なしのまま商品として配布・販売することもできません。
          <br />
          音楽制作に関するソフトウェア(DAWソフトなど)の素材として収録することもできません。
        </p>
      </div>
      <div>
        <h2>クレジット表記は不要ということですが、利用の際の連絡は必要ですか？</h2>
        <p>
          いいえ、利用時の連絡も不要です。
          <br />
          音楽をダウンロードして、そのままご利用ください。
        </p>
      </div>
      <div>
        <h2>YouTubeでライブ配信する際のBGMとして利用できますか？</h2>
        <p>はい、利用できます。</p>
      </div>
      <div>
        <h2>音楽制作時のサンプリング素材として利用したいと考えていますが、可能でしょうか？</h2>
        <p>ダウンロードした音楽を編集して利用する場合は問題ありません。</p>
        <p>音楽を無編集のまま、自身の作品として公開することは禁止しております。</p>
      </div>
      <div>
        <h2>音楽をダウンロードする方法を教えて下さい。</h2>
        <p>
          音楽をダウンロードする場合は、リンク先のBandcampのページから行います。
          <br />
          Bandcampのページにある無料ダウンロード(ブラウザの設定によっては、ここが英語などの表記となっている場合もあります。なお、下記の画像は英語表記となっています。)というボタンを押してください。
        </p>
        <div data-tilt className="thumbnail rounded">
          <ProjectImg
            alt="Bandcampページからダウンロードが行なえます"
            filename="faq/download-01.png"
          />
        </div>
        <p>また、曲名にカーソルを重ねることで、１曲単位のダウンロードも可能です。</p>
        <div data-tilt className="thumbnail rounded">
          <ProjectImg
            alt="曲名にカーソルを重ねることで、１曲単位のダウンロードも可能です"
            filename="faq/download-02.png"
          />
        </div>
        <p>
          無料ダウンロードボタンを押すことで、ダウンロード画面に移動します。移動先の画面で少し待つと、ダウンロードの準備が整いますので、こちらからダウンロードをお願いします。
        </p>
        <div data-tilt className="thumbnail rounded">
          <ProjectImg
            alt="移動先の画面で少し待つと、ダウンロードの準備が整いますので、こちらからダウンロードをお願いします"
            filename="faq/download-03.png"
          />
        </div>
      </div>
      <div>
        <h2>音楽をリクエストした場合、どれぐらいでダウンロードできるようになりますか？</h2>
        <p>
          リクエストいただいた楽曲の制作については、余暇を利用して作成を行うため、作曲のための時間がなかなか取れない場合、お時間をいただくことになります。
          <br />
          その時の状況によって確保できる制作時間は異なるため、どれぐらいで作成できるかはお答えできません。
          <br />
          そのため、気長にお待ちいただけたらと思います。
        </p>
        <p>
          また、難しいリクエストやイメージがつかないものについては対応できませんので、その点も併せてご了承ください。
        </p>
      </div>
    </div>
  );
};

export default () => {
  const { title, lang } = headData;

  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: [
      {
        '@type': 'Question',
        name: '無料で利用可能ですか？',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'はい、無料で利用が可能です。',
        },
      },
      {
        '@type': 'Question',
        name: '商用利用できますか？',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'はい、商用利用が可能です。<br>YouTubeなどを始めとする動画・映像制作や、ウェブコンテンツ制作、ゲーム、ラジオなどのBGMとして利用できます。<br>ただし、ここで配布されている音楽自体をコンテンツにせず、あくまで創作物に用いる音楽の一部としてお使い下さい。素材配布目的の雑誌やサイトなどで、音楽をそのまま二次配布したり、販売することはできません。<br>また、配布されている音楽を編集なしのまま商品として配布・販売することもできません。音楽制作に関するソフトウェア(DAWソフトなど)の素材として収録することもできません。',
        },
      },
      {
        '@type': 'Question',
        name: 'クレジット表記は不要ということですが、利用の際の連絡は必要ですか？',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'いいえ、利用時の連絡も不要です。<br>音楽をダウンロードして、そのままご利用ください。',
        },
      },
      {
        '@type': 'Question',
        name: 'YouTubeでライブ配信する際のBGMとして利用できますか？',
        acceptedAnswer: {
          '@type': 'Answer',
          text: 'はい、利用できます。',
        },
      },
      {
        '@type': 'Question',
        name: '音楽制作時のサンプリング素材として利用したいと考えていますが、可能でしょうか？',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'ダウンロードした音楽を編集して利用する場合は問題ありません。<br>音楽を無編集のまま、自身の作品として公開することは禁止しております。',
        },
      },
      {
        '@type': 'Question',
        name: '音楽をダウンロードする方法を教えて下さい。',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            '音楽をダウンロードする場合は、リンク先のBandcampのページから行います。<br>Bandcampのページにある無料ダウンロード(ブラウザの設定によっては、ここが英語などの表記となっている場合もあります。なお、下記の画像は英語表記となっています。)というボタンを押してください。<br>また、曲名にカーソルを重ねることで、１曲単位のダウンロードも可能です。<br>無料ダウンロードボタンを押すことで、ダウンロード画面に移動します。移動先の画面で少し待つと、ダウンロードの準備が整いますので、こちらからダウンロードをお願いします。',
        },
      },
      {
        '@type': 'Question',
        name: '音楽をリクエストした場合、どれぐらいでダウンロードできるようになりますか？',
        acceptedAnswer: {
          '@type': 'Answer',
          text:
            'リクエストいただいた楽曲の制作については、余暇を利用して作成を行うため、作曲のための時間がなかなか取れない場合、お時間をいただくことになります。<br>その時の状況によって確保できる制作時間は異なるため、どれぐらいで作成できるかはお答えできません。<br>そのため、気長にお待ちいただけたらと思います。<br>また、難しいリクエストやイメージがつかないものについては対応できませんので、その点も併せてご了承ください。',
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title} | よくあるご質問</title>
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
        <html lang={lang || 'en'} />
        <meta
          name="description"
          content="お問い合わせで聞かれる質問をまとめました。音楽をご利用の前にご確認ください。"
        />
      </Helmet>
      <Container>{renderFaq()}</Container>
      <Footer />
    </>
  );
};
